import cookies from "js-cookie";
import { Cookie } from "../cookie";
import { GA4EcommerceEvent } from "./ga4-ecommerce-event-types";

export type GtmEventData = {
    event: string;
    ga4Tracking?: GA4Tracking;
    label?: string;
    url?: string;
    cid?: string;
    gclid?: string;
    market?: "US" | "CA";
    tourCode?: string;
    webSource?: string;
};

export type GA4Tracking = {
    ga4_action: string;
    ga4_object: string;
    /** Additional GA4 properties */
    [key: string]: string | string[];
};

declare global {
    interface Window {
        ga?: { getAll: () => any };
        dataLayer?: {
            push: (args: GtmEventData | GA4EcommerceEvent) => void;
        };
    }
}

/**
 * Pushes event data to the GTM data layer.
 * @param data Data for GA tracking, Enhanced Ecommerce, or other GTM functionality
 */
export const gtmSendEvent = <
    T extends GtmEventData = GtmEventData | GA4EcommerceEvent,
>(
    data: T,
): void => {
    if (typeof window === "undefined") {
        return;
    }
    const { dataLayer } = window;
    if (!dataLayer) {
        return;
    }

    // delete ga4Properties properties from the args object
    const { ga4Tracking, ...newArgs } = data;

    dataLayer.push({
        // set default params. Note ga tracker id is set in GTM
        url: window.location.href,
        cid: cookies.get(Cookie.cid),
        gclid: cookies.get(Cookie.gclid),
        salesforceContactId: cookies.get(Cookie.salesforceContactId),
        brand: process.env.NEXT_PUBLIC_BUSINESS_CODE,
        market: process.env.NEXT_PUBLIC_MARKET_CODE,
        ...newArgs,
        ...ga4Tracking,
    });
};
